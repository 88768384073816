import React from "react";
import { Link } from "react-router-dom";

const Card = ({ image, title, description, link, tags, doc, index }) => {
  const colormap = {
    dashboard: "bg-orange-100 text-orange-800",
    api: "bg-blue-100 text-blue-800",
    application: "bg-red-100 text-red-800",
    website: "bg-violet-100 text-violet-800",
  };

  return (
    // IMAGE

    <div
      className="flex flex-col justify-between rounded shadow-lg bg-white bg-opacity-70"
      key={index}
    >
      <div class="flex flex-row justify-center ">
        {tags?.map((tag) => {
          const color = colormap[tag] || "gray"; // Utilisez "gray" comme couleur par défaut si aucune correspondance n'est trouvée dans `colormap`
          return (
            <span
              className={`${color} text-sm font-small mr-2 my-3 px-2.5 py-0.5 rounded`}
            >
              {tag}
            </span>
          );
        })}
      </div>
      <img class="mx-auto w-5/6 pt-2" src={image} alt="logo"></img>

      {/* Titre et description */}
      <div className="flex-grow px-6 py-2">
        <div className="font-bold text-xl mb-2 text-center">{title}</div>
        <p className="text-gray-800 text-base">{description}</p>
      </div>
      <div className="pb-3">
        {/* LIEN */}

        <div className="flex flex-row justify-center">
          <a
            href={link}
            className="inline-flex items-center px-3 py-2  text-sm font-medium text-center text-white bg-blue-500 rounded-lg hover:bg-blue-700"
          >
            Vers l'app
            <svg
              aria-hidden="true"
              className="w-4 h-4 ml-2 -mr-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>

          <Link
            className="inline-flex items-center ml-2 px-3 py-2 text-sm font-medium text-center text-white bg-slate-400 rounded-lg hover:bg-slate-500"
            key={doc}
            to={`/doc/${doc}`}
          >
            Vers la doc
            <svg
              aria-hidden="true"
              className="w-4 h-4 ml-2 -mr-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;
